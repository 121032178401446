export default {
    DEVELOP_API_URL: 'http://127.0.0.1:8000',
    // DEVELOP_API_URL: "https://smtestapi.mk8s01.dualbit.io",
    PRODUCTION_API_URL: '$SERENAMANUEL_API_SERVER',
    PER_PAGE: [10, 25, 50, 100],
    PER_PAGE_DEFAULT: 10,
    AVAILABLE_ON_REQUEST: "available_on_request",
    CURRICULUM: "curriculum",
    CURRICULUM_SEND_SUCCESS: "curriculum_send_success",
    LAST_UPDATE: "last_update",
    CURRICULUM_SEND_ERROR: "curriculum_send_error",

    //registriation key translate
    REGISTER_SUCCESS: "register_success",
    REGISTER_ERROR: "register_error",
    EMAIL_NOT_SAME: "email_not_same",
    SEND_RESET_PASSWORD_SUCCESS: "send_reset_password_success",
    RESET_PASSWORD_SUCCESS: "reset_password_success",
    PASSWORD_NOT_SAME: "password_not_same",
    NO_PRODUCTS: "no_products",
    AUTH_ERRORS: ['password_too_short', 'password_too_common', 'password_too_similar', 'user_already_present', 'email_invalid'],
    //end

    READ_PRIVACY_DISCLAIMER: "read_privacy_disclaimer",

    COMPONENT_PDF_ERROR: "component_pdf_error",
    LANGUAGES: "livelli_lingue",
    PRIVACY: "privacy",
    REGISTRAZIONE1: "registrazione_1",
    REGISTRAZIONE2: "registrazione_2",
    TECHNIC: "tecnico",
    CONTACTS: "contatti",
    IMAGE_PHOTO: "P",
    IMAGE_TECHNICAL: "T",
    IMAGE_VERTICAL: "V",
    IMAGE_HORIZONTAL: "H",
    TITLE_TEMPLATE: "Serena Manuel | %s",
    ARRAY_CODE: ["code", "codice"],
    CATALOG_SEARCH: 'catalog_search',
    CATALOG_DOWNLOAD_ARTICLE_PDF: 'catalog_download_article_pdf',
    CATALOG_DOWNLOAD_ARTICLE_COMPONENT_PDF: 'catalog_download_article_component_pdf',
    CATALOG_VIEW_ARTICLE: 'catalog_view_article',
    CATALOG_VIEW_ARTICLE_IMAGE: 'catalog_view_article_image',
    CATALOG_DOWNLOAD_RESERVED_CATALOG: 'catalog_download_reserved_catalog',
    DOWNLOAD_PRICE_LIST: 'download_price_list',
    DOWNLOAD_DOWNLOAD: 'download_download',
    DOWNLOAD_RESERVED_DOWNLOAD: 'download_reserved_download',
    RESERVED_CATALOG_TAG: 'catalogo_riservato',
    COLUMNS_CATEGORY: [
        {
            id: 0,
            field: "id"
        },
        {
            id: 1,
            field: "title"
        },
        {
            id: 2,
            field: "color"
        },
        {
            id: 3,
            field: "weight"
        },
        {
            id: 4,
            field: "cover_image"
        }
    ],
    COLUMNS_MATERIAL: [
        {
            id: 0,
            field: "id"
        },
        {
            id: 1,
            field: "title"
        },
        {
            id: 2,
            field: "weight"
        }
    ],
    COLUMNS_FOOTER: [
        {
            id: 0,
            field: "id"
        },
        {
            id: 1,
            field: "title"
        },
        {
            id: 2,
            field: "file"
        },
        {
            id: 3,
            field: "weight"
        },
        {
            id: 4,
            field: "files"
        }
    ],
    MILLIMETERS: "[mm]",
    INCHES: "[in]",
    DATA_INCHES: [
        {
            name: "0",
            value: 0
        },
        {
            name: "1/8",
            value: 3
        },
        {
            name: "1/4",
            value: 6
        },
        {
            name: "3/8",
            value: 10
        },
        {
            name: "1/2",
            value: 13
        },
        {
            name: "3/4",
            value: 19
        },
        {
            name: "1",
            value: 25
        },
        {
            name: "1 1/8",
            value: 28
        },
        {
            name: "1 1/4",
            value: 31
        },
        {
            name: "1 3/8",
            value: 35
        },
        {
            name: "1 1/2",
            value: 38
        },
        {
            name: "1 3/4",
            value: 44
        },
        {
            name: "2",
            value: 50
        },
        {
            name: "2 1/8",
            value: 53
        },
        {
            name: "2 1/4",
            value: 56
        },
        {
            name: "2 3/8",
            value: 60
        },
        {
            name: "2 1/2",
            value: 63
        },
        {
            name: "2 3/4",
            value: 69
        },
        {
            name: "3",
            value: 75
        },
        {
            name: "3 1/8",
            value: 78
        },
        {
            name: "3 1/4",
            value: 81
        },
        {
            name: "3 3/8",
            value: 85
        },
        {
            name: "3 1/2",
            value: 88
        },
        {
            name: "3 3/4",
            value: 94
        },
        {
            name: "4",
            value: 100
        },
    ],
    KEYS_CURRICULUM_REQUIRED: ["experience", "first_name", "last_name", "phone_number", "email", "available_from_date", "birth_city", "birth_province",
    "birth_country", "birth_date", "residence_city", "residence_postal_code", "residence_province", "residence_address", "residence_address_number",
    "residence_country"],
    KEYS_CURRICULUM: [ "first_name", "last_name", "phone_number", "email", "available_from_date", "birth_city", "birth_province",
    "birth_country", "birth_date", "residence_city", "residence_postal_code", "residence_province", "residence_address", "residence_address_number",
    "residence_country", "work_administration_control", "work_sales_italy", "work_sales_foreign", "work_warehouse_shipping", "work_packaging",
    "work_tooling", "work_production", "work_technical_office", "work_other", "health_suitability_any_job", "driver_own_vehicle", "driver_license_type",
    "family_father", "family_mother", "family_partner", "family_sons", "study_primary_school", "study_middle_school", "study_diploma", "study_degree",
    "study_other", "study_last_year", "study_last_institute", "study_last_city", "study_last_province", "study_last_score", "lang_it_comprehension_listen",
    "lang_it_comprehension_read", "lang_it_verbal_interaction", "lang_it_verbal_oral", "lang_it_write", "lang_en_comprehension_listen", "lang_en_comprehension_read",
    "lang_en_verbal_interaction", "lang_en_verbal_oral", "lang_en_write", "lang_fr_comprehension_listen", "lang_fr_comprehension_read", "lang_fr_verbal_interaction",
    "lang_fr_verbal_oral", "lang_fr_write", "lang_de_comprehension_listen", "lang_de_comprehension_read", "lang_de_verbal_interaction", "lang_de_verbal_oral",
    "lang_de_write", "lang_es_comprehension_listen", "lang_es_comprehension_read", "lang_es_verbal_interaction", "lang_es_verbal_oral",
    "lang_es_write", "lang_other_notes", "competences_technical_drawing_read", "competences_technical_drawing_draw", "competences_technical_drawing_cad", "competences_it_windows",
    "competences_it_linux", "competences_it_sap", "competences_it_docs", "competences_it_calc", "competences_it_other_name", "competences_it_other", "competences_metalworkers_forklifts", "competences_metalworkers_welding", "competences_metalworkers_mechanical_presses", "competences_metalworkers_molds_on_press", "competences_metalworkers_mold_construction", "competences_metalworkers_manual_control", "competences_metalworkers_cnc", "competences_metalworkers_robot",
    "experience", "travel_italy", "travel_foreign", "exhibitions_italy", "exhibitions_foreign", "contract_part_time", "contract_apprentice", "contract_fixed_term", "contract_permanent",
    "contract_collaboration", "contract_month_retribution", "contract_month_retribution_type", "contract_month_payouts", "contract_minimum",
    "contract_last_salary", "note", "file", "send_to_user_email", "privacy_consent"]
}