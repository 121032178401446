export default {
    english: "English",
    italian: "Italiano",
    german: "Deutsch",
    french: "Français",
    spanish: "Español",
    news: "Actualités",
    noNews: "Aucune Nouveauté",
    products: "Produits",
    download: "Download",
    curriculum: "Curriculum",
    customerArea: "Espace Client",
    contacts: "Contacts",
    newProduct: "Nouveau produit",
    company: "Société",
    companyText1: "Dal 1963 la nostra Azienda, fondata dalla famiglia Barbieri, progetta e costruisce morsetti, raccordi, supporti ed una gamma completa di accessori ed attrezzature per la zootecnia ed impianti agricoli. I nostri prodotti trovano il loro più ampio utilizzo in questi settori e rispondono pienamente a tutte le esigenze degli allevamenti moderni, sempre più attenti al benessere dell'animale e del settore del vivaismo e della floricoltura.",
    companyText2: "La nostra realtà, completamente italiana, da sempre dedica particolare attenzione alle specifiche esigenze di ogni singolo cliente, selezionando materie prime di qualità e avvalendosi di un know-how di oltre cinquant'anni che ha permesso una continua evoluzione del prodotto e il deposito di alcuni brevetti.",
    companyText3: "Tutto il ciclo produttivo viene realizzato internamente partendo dalla progettazione e costruzione degli stampi fino allo sviluppo del prodotto finito mantenendo alti livelli di qualità e robustezza del nostro prodotto. La gamma si estende a numerosi prodotti sia standard che su misura per qualsiasi tipo di applicazione e con diverse finiture, zincatura a caldo o elettro galvanizzazione, che li rendono durevoli nel tempo e adatti ad ambienti corrosivi. Alcuni dei nostri articoli sono inoltre costruiti in acciaio inox.",
    companyText4: "Il nostro servizio commerciale, affiancato dall'ufficio tecnico e di progettazione, è disponibile per orientare la clientela nella selezione dei componenti più idonei da utilizzare nei singoli casi e nella realizzazione di soluzioni speciali per soddisfare al meglio ogni tipo di esigenza.",
    companyText5: "La nostra forza risiede nella rapida evasione dell'ordine in quanto la maggior parte dei nostri articoli e disponibile a magazzino: garantiamo consegne in 24/48 ore sul territorio nazionale e in tempi rapidi in tutti i Paesi europei. Il crescente successo e l'affermazione sui principali mercati europei ha incoraggiato l'impegno anche nella produzione di prodotti personalizzati per i grandi players internazionali del settore della zootecnia confermandoci come loro partner principale.",
    companyText6: "Ad oggi la nostra sede produttiva si estende per 11.340 mg. di superficie totale dei quali 6.244 mq. coperti ed impiega circa il 90% della propria energia utilizzando energia rinnovabile.",
    findOutMore: "En savoir plus",
    usefulContacts: "Contacts utiles", 
    where: "Où nous trouver ",
    hours: "Heures d'ouverture",
    fromMondayToFriday: "Lundi à vendredi",
    dateIssue: "Data di compilazione",
    availableHiring: "Disponibilità per assunzione dal",
    personalData: "Dati Anagrafici",
    firstName: "Nome",
    lastName: "Cognome",
    birthPlace: "Luogo di nascita",
    birthProvince: "Provincia di nascita",
    birthCountry: "Nazione di nascita",
    birthDate: "Data di nascita",
    placeResidence: "Luogo di residenza",
    postalCode: "CODE POSTAL",
    province: "Provincia",
    address: "Adresse",
    houseNumber: "N° Civico",
    nationality: "Nazionalità",
    telephone: "Télephone",
    email: "Adresse e-mail",
    family: "Nucleo familiare",
    father: "Padre",
    mother: "Madre",
    partner: "Coniuge/compagno",
    child: "Figlio",
    educationalQualification: "Titolo di studio",
    primarySchool: "Licenza elementare",
    middleSchool: "Licenza media",
    diploma: "Diploma",
    graduation: "Laurea",
    other: "Altro",
    sectorWork: "Richiesta settore di lavoro",
    administrationControl: "Amministrazione e controllo",
    commercialItaly: "Commerciale Italia",
    commercialAbroad: "Commerciale Estero",
    warehouseShipping: "Magazzino e spedizioni",
    packaging: "Imballaggio",
    tooling: "Attrezzeria",
    production: "Produzione",
    technicalOffice: "Ufficio tecnico",
    healthConditions: "Condizioni di salute",
    suitabilityAnyJob: "Idoneità a qualsiasi mansione",
    generalNews: "Notizie generali",
    ownCar: "Munito di proprio mezzo",
    driverLicense: "Patente di guida e tipo",
    detailLastTitle: "Dettagli ultimo titolo di studio conseguito",
    yearGraduation: "Anno di conseguimento",
    institute: "Istituto",
    city: "Ville",
    finalVote: "Votazione finale",
    knowledgeForeignLanguages: "Conoscenza lingue straniere",
    home: "Accueil",
    back: "Retour",
    next: "Continuer",
    comprehension_listen: "COMPRENSIONE - Ascolto",
    comprehension_read: "COMPRENSIONE - Lettura",
    verbal_interaction: "PARLATO - Interazione",
    verbal_oral: "PARLATO - Produzione Orale",
    write: "SCRITTO",
    levelsLanguage: "Livelli: A1/2: Elementare - B1/2: Intermedio - C1/2 Avanzato",
    commonEuropean: "Quadro comune europeo di riferimento per la conoscenza delle lingue.",
    knowledges: "Conoscenze",
    nothing: "Nessuna",
    decent: "Discreta",
    great: "Ottima",
    technicalDesign: "Disegno Tecnico",
    informatics: "Informatiche",
    generalMetalworkers: "Metalmeccaniche Generali",
    drawing_read: "Sono in grado di leggere ed interpretare un disegno",
    drawing_draw: "Sono in grado di disegnare",
    drawing_cad: "Sono in grado di disegnare con l'ausilio di mezzi informatici (CAD/CAM)",
    windows: "Sistemi operativi Windows",
    linux: "Sistemi operativi Unix/Linux",
    sap: "SAP",
    docs: "Applicativi per scrittura",
    spreadsheet: "Fogli di calcolo",
    forklifts: "Utilizzo di carelli elevatori",
    welding: "Saldatura manuale",
    mechanical_presses: "Utilizzo di presse meccaniche",
    molds_on_press: "Montaggio stampi su pressa",
    mold_construction: "Costruzione/riparazione stampi",
    manual_control: "Utilizzo di macchine utensili a controllo manuale",
    cnc: "Programmazione di macchine utensili automatiche (CNC)",
    robot: "Programmazione di robot",
    experiences: "Esperienze",
    from: "Dal",
    to: "Al",
    companyWorked: "Azienda/Ente",
    task: "Mansione",
    italy: "Italia",
    abroad: "Estero",
    businessTrips: "Viaggi di lavoro (presso clienti o fornitori), Fiere e Manifestazioni",
    oneDay: "1 giorno",
    fiveDays: "5 giorni",
    beyond: "Oltre",
    contract: "Contratto",
    contractualExpectations: "Aspettative Contrattuali",
    contractualEconomics: "Aspettative Economiche",
    partTime: "Part-Time",
    apprenticeship: "Contratto di apprendistato",
    fixedTime: "Contratto a tempo determinato",
    indeterminateTime: "Contratto a tempo indeterminato",
    collaboration: "Collaborazione/consulenze",
    contractualMinimum: "Minimo contrattuale",
    salary: "Retribuzione mensile desiderata",
    monthlyPayments: "Numero di mensilità",
    gross: "Lorda",
    net: "Netta",
    lastSalary: "Ultima Retribuzione Netta",
    notes: "Note di Interesse",
    attachment: "Allegato",
    sendMyEmail: "Inoltra i dati inseriti al mio indirizzo E-mail",
    readPrivacyDisclaimer: "È necessario aprire il presente link affinché la casella diventi selezionabile. Ho letto l'informativa dei dati personali e presto il consenso.",
    sendCurriculum: "Invia Curriculum",
    productDetail: "Détails du produit",
    availabilityTravel: "Disponibilità agli spostamenti",
    code: "Code",
    diameters: "Diametres",
    bolts: "Boulons",
    packs: "Emballage",
    dimension: "Dimensions",
    article: "Article",
    search: "Recherche",
    reset: "Reset",
    viewDetails: "Détails",
    productNews: "Nouveautés",
    diameter: "Diamètre",
    password: "Mot de passe",
    passwordConfirm: "Confirme le mot de passe",
    emailNotSame: "Les adresses e-mail ne correspondent pas",
    passwordNotSame: "Les mots de passe ne correspondent pas",
    signIn: "Effectuer le login",
    of: "sur",
    whatSearch: "Qu'est-ce que vous cherchez?",
    serenaManuelSpa: "Serena Manuel S.p.A. a socio unico",
    vat: "Nr. de Siret e Nr. TVA",
    info: "Informations",
    generalConditions: "Conditions générales de vente",
    legalNotes: "Notes juridiques",
    technicalNotes: "Remarques techniques",
    personalDataInformation: "Traitement des donnés personnelles",
    cookiePolicy: "Gérer les cookies",
    signUp: "Enregistrement",
    sendRequest: "Soumettre la demande",
    personalArea: "Effectuer le login",
    login: "Connecter",
    logout: "Logout",
    signup: "Enregistrement",
    editProfile: "Modifier votre profil utilisateur",
    forgotPassword: "Mot de passe oublié?",
    signUpTitle: "Enregistrement",
    legalNotesText: "J'ai compris les mentiones légales et le traitement de données à caractère personnel et je souhaite faire l'enregistrement. br/><u (Il faut ouvrir le lien pour activer la fonction.)</u>",
    termsUseText: "J'accepte les termes et conditions d'utilisation du matériel technique.<br/><u>(Il faut ouvrir le lien pour activer la fonction.)</u>",
    onRequest: "Disponible sur demande",
    serenaManuelDescription: "Serena Manuel - Attrezzature Zootecniche",
    serenaManuelKeywords: "Serena Manuel, Luzzara, Attrezzature Zootecniche, Raccords",
    compile: "S'il vous plaît, veuillez compléter les champs ci- dessous pour l'enregistrement à la Espace Client.",
    dataForSignup: "DONNEES DE CONNEXION",
    dataForForgotPassword: "DONNEES POUR AFFICHER LE MOT DE PASSE",
    send: "Envoie",
    country: "Pays",
    fax: "Téléphone",
    repeatEmail: "E-mail à répéter",
    note: "Notes",
    requiredFields: "* Champ obligatoire",
    noSearch: "Aucun produit trouvé",
    productsCategories: "Catégories de produits",
    curriculumPdf: "In aggiunta a quanto stabilito nell’ informativa generale precisiamo che i dati forniti sono trattati esclusivamente ai fini della valutazione della candidatura e che saranno custoditi come per legge per poter contattare il candidato anche in futuro, ove si rendessero disponibili opportunità di lavoro di suo interesse.",
    linkContacts: "<strong> Notez bien: </strong>Les données personnelles envoyées sont traitées selon la note d'information à ce lien.",
    filters: "Recherche",
    placeholderSearchBy: "Recherche le site actuel…",
    fromRange: "De",
    toRange: "À",
    fillRequiredFields: "Remplissez notre formulaire",
    curriculumSent: "Curriculum inviato con successo",
    curriculumError: "Errore durante l'invio",
    attention: "Attention",
    errorComponentPdf: "Pour télécharger la \"Fiche Technique\" voues devez être enregistré",
    downloadFile: "Télécharger",
    getReservedCatalog: "Catalogue reserve",
    sendResetPasswordSuccess: "Le message a été livré, veuillez contrôler votre boite mail",
    sendResetPasswordError: "Erreur lors de l'envoi",
    lastUpdate: "Dernère mise à jour:",
    noProducts: "Aucun article trouvé",
    searchBy: "Rechercher par",
    perPage: "Pour page",
    streetOrSquare: "Via o piazza",
    category: "Catégorie",
    material: "Matériau",
    description: "Description",
    hoursFromMondayToFriday: "8 h 00 à 12 h 00 / 14 h 00 à 18 h 00",
    addressSerenaManuel: "Via Parri 28 - 42045, Luzzara (RE)",
    addressSerenaManuelFooter: "Via F. Parri, 28<br/>42045 Luzzara (RE) Italie",
    fieldRequired: "Champ obligatoire",
    emailNotValid: "Impossible de valider l'adress courriel",
    minPassword: "Le mot de passe doit être composé d'au moins 8 caractères",
    rulesPassword: "Le mot de passe doit comprendre au moins 2 lettres miniscules, 2 lettres majuscules, 2 chiffres et 2 caractères spéciaux",
    successAlert: "AVEC SUCCÈS",
    warningAlert: "Attention",
    errorAlert: "ERREUR",
}